<!-- eslint-disable vue/no-mutating-props -->
<template>
  <Card>
    <template #title>
      <h2>Destek Tipi</h2>
    </template>
    <template #subtitle>
      <p>Radyoya destek olmak için farklı seçeneklerimiz var.</p>
    </template>
    <template #content>
      <ul class="mt-4">
        <li>
          <label class="inline-flex items-start gap-1.5 mb-4 cursor-pointer">
            <RadioButton v-model="currentItem.type" name="type" value="user" />
            Destek olacağım programı seçmek istiyorum.
          </label>
        </li>
        <li>
          <label class="inline-flex items-start gap-1.5 mb-2 cursor-pointer">
            <RadioButton v-model="currentItem.type" name="type" value="ar" />
            Destek olacağım programın seçimini radyoya bırakıyorum.
          </label>
        </li>
      </ul>
    </template>
    <template #footer>
      <div class="text-right">
        <router-link :to="{name: 'support', params: {step: 'destek-detayı'}}">
          <Button size="small" label="DEVAM" raised icon="pi pi-arrow-circle-right" />
        </router-link>
      </div>
    </template>
  </Card>
</template>

<script>
export default {
  props: ["current-item"],

  watch: {
    //reset current item.
    "currentItem.type" () {
      //eslint-disable-next-line
      this.currentItem.slots = [];
      //eslint-disable-next-line
      this.currentItem.desiredCredits = 0;
    },
  }
}
</script>