<template>
  <div class="pb-16">

    <div class="mx-auto h-14 border-b drop-shadow-sm bg-white py-2 px-4 flex items-center gap-2 fixed w-full top-0">

      <div class="grow">
        <router-link :to="{ name: ! $store.member ? 'home' : 'member-info' }">
          <img src="@/assets/ApacikRadyo_Logo.svg" alt="Apaçık Radyo logo" class="h-7 lg:h-10" />
        </router-link>
      </div>

      <div v-if="$store.member">
        <div class="flex gap-1 items-center">
          <Button outlined size="small" :disabled="! $store.order.credits"
            class="text-sm font-light"
            @click="$refs['basket-overlay'].toggle($event)">
              <i class="pi pi-gift mr-2"></i>
              {{ $store.order.credits === 1 ? 'Yarım' :  $store.order.credits / 2 }} saat
          </Button>

          <OverlayPanel ref="basket-overlay" class="w-96">
            <p class="mb-6"><b>Desteğiniz:
              {{ $store.order.credits === 1 ? 'Yarım' :  $store.order.credits / 2 }} saat</b></p>
            <p class="text-right"><b>Toplam: ₺{{ $store.order.total }}</b></p>
            <hr class="border-b my-4" />
            <router-link :to="{name: 'basket'}" class="block text-center"
              @click="$refs['basket-overlay'].toggle()">
              ÖDEME SAYFASINA GİT
            </router-link>
          </OverlayPanel>
        </div>
      </div>

      <Button text title="Kullanıcı" icon="pi pi-user"
        @click=" $refs['user-menu'].toggle($event)" size="small"
        v-if="ui.ready && $store.member" />

      <Button icon="pi pi-align-justify" text size="small"
        @click="$refs['content-menu'].toggle($event)" aria-haspopup="true"
        aria-controls="content-menu" />

    </div>

    <div class="container mx-auto pt-[4.5rem] lg:pt-28 p-inputtext-sm px-2 md:px-4">
      <router-view v-if="routerViewVisible" @logout="logout()" />
      <!-- <Toast />
      <ConfirmDialog /> -->

      <Login v-if="loginVisible" @member="$store.member = $event" />
    </div>

    <Menu ref="content-menu" id="content-menu" :model="contentMenuItems" :popup="true" />
    <Menu ref="user-menu" id="user-menu" :model="userMenuItems" :popup="true" />
  </div>

</template>

<script>
//import Menubar from 'primevue/menubar';
import Login from './views/Login.vue';
export default {
  components: {
    //Menubar,
    Login,
  },

  data () {
    return {
      ui: {
        ready: false,
      }
    }
  },

  computed: {

    contentMenuItems () {
      return [
        { label: "KVKK", to: {name: 'content-kvkk'} },
        { label: "İptal ve iade", to: {name: 'content-iptal-iade'} },
        { label: "Sözleşme", to: {name: 'content-sozlesme'} },
        { label: "Kullanım koşulları", to: {name: 'content-kullanim-kosullari'} },
      ];
    },

    userMenuItems () {
      return [
        { label: "Bilgilerim", to: {name: 'member-info'}, icon: "pi pi-user-edit" },
        { label: "Çıkış", command: () => this.logout(), icon: "pi pi-power-off" }
      ]
    },

    menu () {
      return [];
    },

    publicRoutes () {
      return ["register", "content-kvkk", "content-iptal-iade", "content-sozlesme",
      "content-kullanim-kosullari", "pass-forgot", "pass-reset", "request-receipt"];
    },

    loginVisible () {
      if (! this.ui.ready) return false;
      if (this.publicRoutes.includes(this.$route.name)) return false;
      return ! this.$store.member;
    },

    routerViewVisible () {
      if (! this.ui.ready) return false;
      if (this.publicRoutes.includes(this.$route.name)) return true;
      return this.$store.member;
    },
  },

  methods: {
    async logout () {
      await this.$post("/logout");
      this.$store.member = null;
    },
  },

  watch: {
    "$store.member": {
      handler () {
        if (! this.$store.member) {
          if (this.publicRoutes.includes(this.$route.name) || ! this.$route.name) {
            //nothing to do
          } else {
            return this.$router.replace({name: "home"});
          }
        }
        if (this.$route.name === 'home') this.$router.replace({name: "member-info"});
      }, immediate: true,
    },
  },

  async created () {
    //Old site had # urls and some users may have receipt-wanted urls in their emails.
    //Redirect them to the new page:
    if (location.hash.indexOf('#/fatura-iste/') === 0) {
      location.href = location.href.replaceAll("#/", "");
      return;
    }
    const ret = await this.$get("/");
    this.$store.member = ret.member;
    this.$store.settings = ret.settings;
    this.ui.ready = true;
  },
}
</script>
